.main {
    border: 1rem solid var(--color-accent1);
    border-radius: max(25px, 25rem);
    height: max(57px, 57rem);
    text-align: center;
    width: max(30px, 30rem);
}

:global(.mobile) .main {
    height: 57rem;
    width: 30rem;
}

.text {
    color: var(--color-accent1);
    margin: 0;
    margin-top: calc(var(--page-height) * 0.01);
    text-align: center;
    padding: 0;
    position: relative;
    left: 0%;
}

.dot1,
.dot2 {
    animation-duration: 2.5s;
    animation-name: scrolldown;
    animation-iteration-count: infinite;
    fill: var(--color-accent1);
}

.dot2 {
    animation-delay: 1.25s;
}

@keyframes scrolldown {
    0% {
        opacity: 0;
        transform: translate(0, -12px);
    }

    50% {
        opacity: 1;
        transform: translate(0, 0);
    }

    100% {
        opacity: 0;
        transform: translate(0, 12px);
    }
}