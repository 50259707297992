@font-face {
    font-family: "Intro";
    src: url("@fonts/Intro Regular Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "Intro Book";
    src: url("@fonts/Intro Book Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "Heebo";
    src: url("@fonts/Heebo-ExtraLight.ttf");
    font-weight: 200;
}

@font-face {
    font-family: "Heebo";
    src: url("@fonts/Heebo-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Heebo";
    src: url("@fonts/Heebo-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Heebo";
    src: url("@fonts/Heebo-Medium.ttf");
    font-weight: 500;
}