.root {
    width: 100%;
    height: 100%;
}

.static {
    position: absolute;
    right: 120rem;
    height: 100%;
    width: 800rem;
    max-width: calc(50% - 120rem);

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 2;
    text-align: end;
}

:global(.mobile) .static {
    left: 60rem;
    bottom: 120rem;
    height: calc(60% - 120rem);
    width: calc(100% - 120rem);
    max-width: 100%;

    text-align: start;
}

.staticContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    transition: height 1s ease;
}

:global(.mobile) .staticContent {
    align-items: flex-start;
    justify-content: flex-end;
}

.dynamic {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0rem;
}

.header h1 {
    display: inline;
}

.header h2 {
    display: inline;
}

.brief {
    width: 720rem;
    max-width: 100%;
}

:global(.mobile) .brief {
    width: 100%;
}

.canvasOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.canvasOverlay>div {
    position: absolute;
}

.comingSoon {
    width: 250rem;
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    border-radius: var(--radius);
    border: 1rem solid var(--color-accent1);
    background-color: var(--color-accent1);
    padding: 10rem 20rem;

    color: var(--color-accent2);
    font-size: calc(var(--size3) * 0.8);
    font-family: "Intro", sans-serif;
}

.overlayTitle {
    font-size: var(--size2);
}