.root {
    width: 100%;
    height: 100%;
}

.content {
    width: 100%;
    height: 100%;
    position: relative;
}

.static {
    position: absolute;
    left: 200rem;
    height: 100%;
    width: 800rem;
    max-width: calc(50% - 200rem);

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 2;
}

:global(.mobile) .static {
    left: 60rem;
    bottom: 120rem;
    height: calc(65% - 120rem);
    width: calc(100% - 120rem);
    max-width: 100%;
}

.staticContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: height 1s ease;
}

:global(.mobile) .staticContent {
    justify-content: flex-end;
}

.dynamic {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0rem;
}

.header h1 {
    display: inline;
}

.header h2 {
    display: inline;
}

.brief {
    width: 720rem;
    max-width: 100%;
}

:global(.mobile) .brief {
    width: 100%;
}

:global(.mobile) .brief p {
    font-size: calc(var(--size3) * 0.9);
}

.canvasUnderlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
    pointer-events: none;
}

.canvasUnderlay>div {
    position: absolute;
}

.background {
    width: 600rem;
    height: 600rem;
    border-radius: 600rem;
    background: var(--color-gradient1);
    background: linear-gradient(135deg, var(--color-gradient1) 0%, var(--color-gradient2) 100%);
}

.canvasOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.canvasOverlay>div {
    position: absolute;
}