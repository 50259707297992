.root {
    width: 100vw;
    height: calc(var(--page-height) - var(--navbar-height));
    position: relative;
    transition: top 1s ease;

    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}