.root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;

    cursor: pointer;
    user-select: none;

    font-family: "Heebo", sans-serif;
    font-weight: 500;

    transition: color 0.25s ease;
}

.active {
    color: var(--color-accent3);
    text-decoration: underline;
    text-underline-offset: 10rem;
    font-weight: 500;
}

:global(.mobile) .root {
    width: 100%;
    height: 120rem;
    font-size: calc(var(--size2) * 1.5);
}