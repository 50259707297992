.root {
    padding: 40rem;
    padding-top: 0rem;
    width: 100%;
    height: 100%;
}

.content {
    border-radius: 40rem;
    width: 100%;
    height: 100%;
    background: var(--color-gradient1);
    background: linear-gradient(135deg, var(--color-gradient1) 0%, var(--color-gradient2) 100%);
    position: relative;
}