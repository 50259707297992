.root {
    cursor: pointer;
    user-select: none;
    border-radius: var(--radius);
    border: 1rem solid var(--color-accent1);
    background-color: transparent;
    padding: 10rem 20rem;

    color: var(--color-accent1);
    font-size: calc(var(--size3) * 0.8);
    font-family: "Intro", sans-serif;

    transition: background-color 0.5s ease, color 0.5s ease;
}

.active {
    background-color: var(--color-accent1);
    color: var(--color-button-text);
}