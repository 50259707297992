.root {
    position: relative;
    width: 100%;
    height: 100%;
}

.content {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: calc(100% - 5px); /* No idea where those 5px are coming from */
}

.item {
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}