.root {
    width: 100vw;
    height: var(--navbar-height);
    color: var(--color-accent2);
    background-color: var(--color-background);
    position: relative;
    z-index: 100;
    font-weight: 500;
    font-size: var(--size3);
    text-align: center;

    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 200rem;
    height: 60rem;
    margin-left: var(--margin);
    margin-right: 20rem;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
}

.logo img {
    width: 100%;
}

.buttons {
    height: 60rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 820rem;

    transition: opacity 0.5s ease;
}

.contact {
    width: 200rem;
    height: 42rem;
    margin-right: var(--margin);
    margin-left: 20rem;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    font-family: "Heebo", sans-serif;
    font-size: var(--size3);

    cursor: pointer;
    user-select: none;

    border: 1rem solid var(--color-accent2);
    border-radius: var(--radius);

    transition: opacity 0.5s ease;
}

.menu {
    width: 60rem;
    height: 60rem;
    margin-right: var(--margin);
}

.foldout {
    position: fixed;
    top: var(--navbar-height);
    width: var(--page-width);
    height: 0rem;
    overflow: hidden;
    background-color: var(--color-background);
    z-index: 100;

    transition: height 0.5s ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.content {
    width: 80%;
    height: calc(100% - var(--navbar-height) * 1.5);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

:global(.mobile) .contact {
    transform: scale(1.5);
    margin: 0;
}