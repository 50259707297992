.root {
    width: 100vw;
    height: var(--page-height);
    position: fixed;
    left: 0rem;
    top: 0rem;
    z-index: 10000;
    background-color: var(--color-background);
    color: var(--color-accent2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    width: 100%;
    height: 100%;
    transform-origin: top left;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 80%;
    max-width: 400rem;
    height: var(--navbar-height);

    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 100%;
}

.percent {
    margin: 20rem;
}

.target {
    width: 200rem;
    height: 60rem;
    margin-left: var(--margin);
    margin-right: 20rem;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
}

