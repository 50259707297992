.root {
    width: 100%;
    height: 100%;
}

.static {
    position: absolute;
    right: 120rem;
    height: 100%;
    width: 800rem;
    max-width: calc(50% - 120rem);

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 2;
    text-align: end;
}

:global(.mobile) .static {
    left: 60rem;
    bottom: 120rem;
    height: calc(60% - 120rem);
    width: calc(100% - 120rem);
    max-width: 100%;

    text-align: start;
}

.staticContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    transition: height 1s ease;
}

:global(.mobile) .staticContent {
    align-items: flex-start;
    justify-content: flex-end;
}

.dynamic {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0rem;
}

.header h1 {
    display: inline;
}

.header h2 {
    display: inline;
}

.feature {
    width: 720rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

:global(.mobile) .feature {
    width: 100%;
}

h1.featureTitle {
    font-size: calc(var(--size1) * 0.5);
}

.canvasOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.canvasOverlay>div {
    position: absolute;
}

.analyticsOverlay {
    width: 700rem;
}

:global(.mobile) .analyticsOverlay {
    width: calc(100% - 120rem);
    max-width: 520rem;
}

.buttons {
    position: absolute;
    bottom: 170rem;
    width: 100%;
    pointer-events: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

:global(.mobile) .buttons {
    bottom: unset;
    width: calc(100% - 120rem);
    transform: translateY(250rem);
}

.buttons button {
    margin: 0rem 5rem;
    width: 160rem;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: initial;
}

:global(.mobile) .buttons button {
    width: 150rem;
    font-size: calc(var(--size3) * 0.7);
}

.icon {
    width: 75%;
    height: 75%;
    pointer-events: initial;
    cursor: pointer;
}

.analyticTitle {
    position: absolute;
    width: 100%;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
}

.analyticTitle h1 {
    font-size: var(--size2);
}