.root {
    position: relative;
    height: calc(var(--radius) * 2);
    width: fit-content;
    max-width: 100%;

    cursor: pointer;
    border-radius: var(--radius);
    background-color: var(--color-accent1);
    color: var(--color-accent2);

    transition: width 0.5s ease;
}

.content {
    width: fit-content;
    max-width: 100%;
    height: 100%;

    cursor: pointer;
    user-select: none;
    padding: 10rem 20rem;

    font-size: calc(var(--size4));
    font-family: "Intro", sans-serif;
    white-space: nowrap;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.constraint {
    position: absolute;
}

.anchor {
    position: absolute;
    width: 0;
    height: 0;
}

.pin {
    transform: translate(0%, -50%);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
}