.root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0rem;
}

.controls {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: none;
}

.button {
    width: 28rem;
    height: 28rem;
    pointer-events: initial;
    cursor: pointer;
}

.button.flip {
    transform: scaleX(-1);
}