.root {
    width: 100%;
    height: 100%;
}

.static {
    position: absolute;
    left: 120rem;
    height: 100%;
    width: 800rem;
    max-width: calc(50% - 120rem);

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 2;
}

:global(.mobile) .static {
    left: 60rem;
    bottom: 120rem;
    height: calc(60% - 120rem);
    width: calc(100% - 120rem);
    max-width: 100%;
}

.staticContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: height 1s ease;
}

.dynamic {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0rem;
}

.header {
    transform-origin: left center;
}

.header h1 {
    display: inline;
}

.header h2 {
    display: inline;
}

.brief {
    width: 612rem;
    max-width: 100%;
}

:global(.mobile) .brief {
    width: 100%;
}

.brief h2 {
    display: inline;
}