.root {
    width: 100%;
    height: var(--footer-height);
    background: var(--color-gradient1);
    background: linear-gradient(135deg, var(--color-gradient1) 0%, var(--color-gradient2) 100%);
    position: relative;

    font-family: "Heebo", sans-serif;
    font-weight: 200;

    display: flex;
    justify-content: center;
    align-items: center;
}

:global(.mobile) .root {
    padding-top: 60rem;
    align-items: flex-start;
}

.content {
    width: 1400rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

:global(.mobile) .content {
    width: calc(100% - 120rem);
    flex-direction: column;
    row-gap: 40rem;
}

.content h2 {
    margin-top: 0;
}

.content > div {
    width: 280rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.navigation {
    width: 220rem !important;
}

.navigation .button {
    cursor: pointer;
    user-select: none;
}

.app {
    width: 340rem !important;
}

.login {
    width: 200rem;
    height: 42rem;
    margin-top: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    font-family: "Heebo", sans-serif;
    font-size: var(--size3);

    cursor: pointer;
    user-select: none;

    border: 1rem solid var(--color-foreground);
    border-radius: var(--radius);
}

.info p {
    margin: 0;
    margin-bottom: 1em;
    user-select: text;
}

.socials > div {
    display: flex;
    flex-direction: row;
}

.socials a {
    width: 40rem;
    height: 40rem;
    margin-right: 20rem;

    cursor: pointer;
    user-select: none;
}

.about {
    width: 240rem;
}

.about p {
    margin: 0;
    margin-top: 10px;
}

.logo {
    height: 46rem;
}

.description {
    font-size: calc(var(--size4) * 1.3);
}

.copyright {
    font-size: var(--size4);
}