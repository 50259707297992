.root {
    width: 100%;
    height: 100%;
}

.static {
    position: absolute;
    left: 120rem;
    height: 100%;
    width: 800rem;
    max-width: calc(50% - 120rem);

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 2;
}

:global(.mobile) .static {
    left: 60rem;
    bottom: 120rem;
    height: calc(60% - 120rem);
    width: calc(100% - 120rem);
    max-width: 100%;
}

.staticContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    transition: height 1s ease;
}

:global(.mobile) .staticContent {
    justify-content: flex-end;
}

.dynamic {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0rem;
}

.header h1 {
    display: inline;
}

.header h2 {
    display: inline;
}

.brief {
    width: 720rem;
    max-width: 100%;
}

:global(.mobile) .brief {
    width: 100%;
}

.feature {
    position: absolute;
    width: 720rem;
    max-width: calc(50% - 120rem);
    height: 200rem;
    bottom: 120rem;
    z-index: 3;
}

:global(.mobile) .feature {
    width: calc(100% - 120rem);
    max-width: 100%;
    bottom: unset;
    transform: translateY(200rem);
}

.canvasOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
}

.canvasOverlay>div {
    position: absolute;
}

.iconOverlay {
    width: 800rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300rem;
    top: 120rem;
}

:global(.mobile) .iconOverlay {
    width: calc(100% - 120rem);
    top: unset;
    transform: translateY(-160rem);
}

.titleOverlay {
    width: 800rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 150rem;
}

:global(.mobile) .titleOverlay {
    width: calc(100% - 120rem);
    top: unset;
    transform: translateY(-200rem);
}

.overlayTitle {
    font-size: var(--size2);
}

.buttons {
    margin-top: 30rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

:global(.mobile) .buttons {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10rem;
}

.buttons button {
    margin: 0rem 5rem;
    width: 200rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    pointer-events: initial;
    cursor: pointer;
}

.icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

:global(.mobile) .icons {
    flex-wrap: wrap;
    row-gap: 10rem;
    transform: translateY(-20rem);
}

.icons>div {
    margin: 0rem 20rem;
}

.iconTitle {
    position: absolute;
    width: 100%;
    bottom: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    user-select: none;
}

.iconTitle h1 {
    font-size: var(--size2);
}

.forceWrap {
    flex-basis: 100%;
    height: 0rem;
}