@import url("./fonts.css");

:root {
    --navbar-height: 120rem;
    --footer-height: 400rem;
    --button-height: 40rem;

    --margin: 50rem;
    --radius: 20rem;

    --size1: 80rem;
    --size2: 26rem;
    --size3: 23rem;
    --size4: 16rem;

    --color-background: #dfeeef;
    --color-gradient1: #5ac7c3;
    --color-gradient2: #3a939e;
    --color-foreground: #ffffff;
    --color-accent1: #26ffff;
    --color-accent2: #0f687f;
    --color-accent3: #21b0c1;
    --color-button-text: #44bcb3;
    --color-icon: #25b7c9;

    --page-width: 100vw;
    --page-height: 100vh;
}

.mobile {
    --footer-height: 1440rem;
}

* {
    box-sizing: border-box;
    user-select: none;
    touch-action: none;
}

body {
    margin: 0;
    font-family: "Intro", sans-serif;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-background);
    color: var(--color-foreground);
    font-size: var(--size3);
    overflow: hidden;
}

h1 {
    font-family: "Intro", sans-serif;
    font-weight: 400;
    font-size: var(--size1);
}

h2 {
    font-family: "Intro Book", sans-serif;
    font-weight: 400;
    font-size: var(--size2);
}

p {
    font-family: "Heebo", sans-serif;
    font-weight: 200;
    font-size: var(--size3);
}

p strong {
    font-weight: 400;
}

.accent1 {
    color: var(--color-accent1);
}

.accent2 {
    color: var(--color-accent2);
}

.accent3 {
    color: var(--color-accent3);
}

a,
button {
    all: unset;
}

.scroll {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30rem;
    bottom: 0rem;
    position: absolute;
    font-family: "Heebo", sans-serif;
    font-weight: 400;
    font-size: max(16px, 16rem);
    pointer-events: none;
    user-select: none;
    z-index: 99;

    transition: opacity 0.5s ease;
}

.mobile .scroll {
    transform: scale(0.625);
    transform-origin: bottom center;
}